import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PuppiesPage = ({ data }) => {
  const list = data.allContentfulIlmoitus.edges.map(edge => (
    <div
      key={edge.node.id}
      dangerouslySetInnerHTML={{
        __html: edge.node.content.childMarkdownRemark.html,
      }}
    />
  ))

  return (
    <Layout>
      <SEO title="Puppies" />
      <h1>Puppies</h1>
      <>{list}</>

      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}
export const query = graphql`
  {
    allContentfulIlmoitus(filter: { node_locale: { eq: "fi" } }) {
      edges {
        node {
          title
          node_locale
          id
          createdAt
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

export default PuppiesPage
